/* calendar */
.react-calendar {
  width: 400px;
  transform: scale(1.3);
  background: white;
  line-height: 1.7;
  background-color: #11172a;
  margin: auto;
}

.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 100%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0);
}
.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
  background-color: rgba(255, 255, 255, 0);
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  color: white;
  font-size: larger;
}
.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
  visibility: hidden;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: rgba(255, 255, 255, 0);
}

.react-calendar__tile {
  color: white;
}

abbr[title] {
  text-decoration: none;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bolder;
  font-size: 0.9em;
  text-decoration: none;
  color: white;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}
.react-calendar__month-view__days__day--weekend {
  color: white;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #4d4d4d;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
  background-color: rgba(255, 255, 255, 0);
}
.react-calendar__tile:disabled {
  background-color: rgba(255, 255, 255, 0);
  color: #4d4d4d;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: rgba(255, 255, 255, 0);
}
.react-calendar__tile--now {
  background-color: rgba(255, 255, 255, 0);
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background-color: rgba(255, 255, 255, 0);
}
.react-calendar__tile--hasActive {
  background-color: rgba(255, 255, 255, 0);
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background-color: rgba(255, 255, 255, 0);
}
.react-calendar__tile--active {
  background-color: rgba(255, 255, 255, 0);
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background-color: rgba(255, 255, 255, 0);
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: rgba(255, 255, 255, 0);
}

@media all and (max-width: 600px) {
  .react-calendar {
    transform: scale(1.1);
  }
}

@media all and (max-width: 420px) {
  .react-calendar {
    margin: 0px;
    width: 100%;
    transform: scale(0.96);
  }
}
