.pageContainer {
  margin: 0;
  font-family: "Roboto Condensed", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #11172a;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.pageContainer {
  position: relative;
  min-height: 100vh;
  background-color: #11172a;
}
.content {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 60px;
}
.footer {
  position: absolute;
  height: 60px;
  bottom: 0;
}
.flexContainer {
  padding-top: 75px;
  display: flex;
  flex-direction: column;
  transform: scale(1.1);
  gap: 50px;
}
.button {
  background-color: #e03c90;
  color: white;
  border: 0px;
  border-radius: 0px;
  font-weight: 600;
  padding: 15px;
}
.button:focus {
  background-color: white;
  color: #e03c90;
  padding: 15px;
}
.button:hover {
  background-color: white;
  color: #e03c90;
  padding: 15px;
  transform: scale(1.2);
  border: 1px solid white;
}
.button:disabled {
  background-color: #e03cad;
  color: white;
}
/* navbar */
.nav {
  background-color: #11172a;
  color: white;
  letter-spacing: 2px;
  font-weight: light;
}
.navLink {
  background-color: #11172a;
  border: 0px;
  text-decoration: none;
  color: white;
}
.navLink:hover {
  color: #e93e8f;
}
/* sidebar */
.sidebar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  background-color: #00a191;
  height: 100%;
  z-index: 3;
  opacity: 0.9;
}
.sideList {
  height: 100%;
  display: inline-block;
  align-items: center;
  justify-content: center;
  padding: 35px;
}
.sideList li {
  list-style: none;
}
.sideList li a {
  text-decoration: none;
  color: white;
}
.sideList li a:hover {
  color: #e03c90;
}
.sidehr {
  border: 3px solid white;
}
.sidebarButton {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 18px;
  background-color: #00a191;
  border: 1px solid #00a191;
  color: #3fb8ac;
  font-weight: 700;
  font-size: 40px;
  border-radius: 2px;
}
.sidebarButton:hover {
  transform: scale(1.1);
}
/* kasse */
.boxMain {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  max-width: 100%;
  margin: auto;
  height: 80%;
  margin-top: 30px;
}
.firstHalf {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.secondHalf {
  width: 30%;
}
.selectNum {
  color: white;
  background-color: #12172b;
}
.inputCode {
  color: white;
  background-color: #12172b;
}
.inputCode:focus {
  color: white;
  background: #12172b;
}
.section {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 100px;
}
.sectionLeft {
  flex-direction: row-reverse;
}
/* order */
.listGroup {
  background-color: #11172a;
  border: 1px solid #4c505f;
  padding: 30px;
}
.listGroupItem {
  background-color: #11172a;
  color: white;
}
/* kontakt */
.kontaktBox {
  transform: scale(1);
}
.row {
  padding: 3px;
}
.dropdownToggle {
  background-color: #12172b;
  border: 1px solid white;
  color: white;
}
.dropdownToggle:hover {
  background-color: #12172b;
  border: 1px solid white;
  color: white;
}
/* payment */
.deleteButton:hover {
  color: #e03c90;
  background-color: white;
  transform: scale(1.2);
}
.deleteButton {
  width: 50px;
  height: 50px;
}
.formCheckInput {
  background-color: white;
  transform: scale(1.5);
  margin-left: 0;
}
.formCheckInput:checked {
  background-color: #e03c90;
  border-color: #e03c90;
}
.formCheckInput:focus {
  border-color: #e03c90;
}
.addressdiv {
  border: 1px solid #4c505f;
  padding: 20px;
  border-radius: 7px;
}
.promodiv {
  display: flex;
  flex-direction: row;
}
.inputText {
  border: 1px solid #4c505f;
  border-radius: 10px;
}
.btn-outline-secondary {
  background-color: #e03c90;
  color: white;
  border: 0px solid;
}
.btn-outline-secondary:hover {
  background-color: white;
  color: #e03c90;
}
@media all and (max-width: 420px) {
  .pageContainer {
    padding: 5px;
    font-size: small;
  }
  .boxMain {
    padding: 0px 20px 0px 20px;
  }
  .calendarContainer {
    padding: 0px;
    display: block;
    width: 100%;
  }
  .button {
    font-size: smaller;
    padding: 10px;
  }
}