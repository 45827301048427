.pageContainer {
  margin: 0;
  font-family: "Roboto Condensed", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1d1d1d;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.pageContainer {
  position: absolute;
  width: 100%;
  min-height: 100vh;
  background-color: #1d1d1d;
}
.content {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.container {
  padding-bottom: 400px;
}
.footer {
  position: absolute;
  background-color: black;
  padding-top: 40px;
  padding-bottom: 40px;
  width: 100%;
  height: 270px;
  bottom: 0;
  z-index: 4;
}
.footerList {
  list-style-type: none;
  margin-top: 15px;
  padding: 0;
  font-size: small;
}
.flexContainer {
  padding-top: 75px;
  display: flex;
  flex-direction: column;
  transform: scale(1.1);
  gap: 50px;
}
.buttonSidebar {
  background-color: black;
  color: white;
  border: 0px;
  border-radius: 0px;
  font-weight: bolder;
  padding: 15px;
  font-size: small;
  margin: 5px 0px 35px 0px;
}
.buttonSidebar:hover {
  background-color: black;
  color: white;
  transform: scale(1.1);
}
.buttonSidebar:focus {
  background-color: #3d69b1;
  color: black;
  padding: 15px;
}
.sidebarName {
  font-size: small;
}
.button {
  background-color: black;
  color: white;
  border: 0px;
  border-radius: 0px;
  font-weight: 600;
  padding: 15px;
}
.button:focus {
  background-color: #3d69b1;
  color: black;
  padding: 15px;
}
.button:link,
.button:visited {
  background-color: black;
  color: white;
  border: 0px;
  border-radius: 0px;
  font-weight: 600;
  padding: 15px;
}
.button:hover,
.button:hover {
  background-color: #3d69b1;
  color: black;
  padding: 15px;
  transform: scale(1.2);
  border: 1px solid white;
}
.button:disabled {
  background-color: #3d69b1;
  color: white;
}
/* sidebar */
.sidebar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  background-color: #3d69b1;
  height: 100%;
  width: 320px;
  z-index: 3;
  opacity: 1;
}
.sideList {
  height: 100%;
  display: inline-block;
  align-items: center;
  justify-content: center;
  padding: 35px;
}
.sideList li {
  list-style: none;
}
.sideList li a {
  text-decoration: none;
  color: white;
}
.sideList li a:hover {
  color: #3d69b1;
}
.sidehr {
  border: 3px solid white;
}
.sidebarButton {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 18px;
  background-color: #3d69b1;
  border: 1px solid #3d69b1;
  color: white;
  font-weight: 700;
  font-size: 40px;
  border-radius: 2px;
}
.sidebarButton:hover {
  transform: scale(1.1);
}
.logo {
  width: 140px;
  height: 80px;
  margin: auto;
}
.footerLogo {
  width: 140px;
  height: 80px;
  margin: auto;
}
@media all and (max-width: 420px) {
  .calendarContainer {
    padding: 0px;
    display: block;
    width: 100%;
  }
  .button {
    font-size: smaller;
    padding: 10px;
  }
  .sidebar {
    width: 255px;
  }
}