.bgColorRed {
  background-color: #DB1323;
}
.subLink1 {
  display: none;
}
.mainLink1:hover .subLink1 {
  display: block;
}
.subLink2 {
  display: none;
}
.mainLink2:hover .subLink2 {
  display: block;
}
.navbarNav li:hover > ul.dropdownMenu {
  display: block;
}
.dropdownSubmenu {
  position: relative;
}
.dropdownSubmenu > .dropdownMenu {
  top: 0;
  left: 100%;
  margin-top: -6px;
}
/* rotate caret on hover */
.dropdownMenu > li > a:hover:after {
  text-decoration: underline;
  transform: rotate(-90deg);
}
.navbar {
  background-color: #c8eef5;
  margin-left: auto;
  margin-right: auto;
  padding: 25px 16px 50px 0px;
  max-width: 1400px;
}
.navElement {
  display: inline-block;
  padding: 20px 68px 20px 50px;
  margin: 0px;
  border: 0px 1px 0px 0px solid black;
  border-right: 1px solid black;
  font-size: small !important;
  transform: scale(0.9) !important;
}
.navElement:nth-last-child(1) {
  display: inline-block;
  padding: 20px 50px 20px 50px;
  margin: 0px;
  border: none;
  font-size: small !important;
  transform: scale(0.9) !important;
}
.navElement a {
  text-decoration: none;
  text-transform: uppercase;
  color: black;
}
.navElement a:hover {
  text-decoration: none;
  text-transform: uppercase;
  color: #dbdbdb;
}
.fill {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #DB1323;
  padding: 0px 0px 25px 0px;
}
.fill img {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
}
.header {
  background-color: #c8eef5;
  overflow: hidden;
  padding: 0px;
  margin: 0px;
}
.dropdownContent {
  float: left;
  margin-top: 20px;
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-left: -31.5%;
}
.dropdownContent li {
  list-style: none;
  padding: 10px;
  border-bottom: 1px solid black;
}
.dropdownContent li:nth-last-child(1) {
  list-style: none;
  padding: 10px;
  border-bottom: none;
}
.dropdown:hover .dropdownContent {
  display: block;
}
@media all and (max-width: 1320px) {
  .navElement {
    padding: 10px 34px 10px 25px;
  }
  .navElement:nth-last-child(1) {
    padding: 10px 25px 10px 25px;
  }
}
@media all and (max-width: 920px) {
  .navElement {
    padding: 5px 17px 5px 12px;
  }
  .navElement:nth-last-child(1) {
    padding: 5px 12px 5px 12px;
  }
}
@media all and (max-width: 700px) {
  .navbar {
    padding: 25px 0px 50px 0px;
  }
  .navElement:nth-child(1) {
    padding: 0px 0px 0px 20px;
  }
  .navElement {
    padding: 0px 10px 0px 0px;
  }
  .navElement:nth-last-child(1) {
    padding: 0px 10px 0px 0px;
  }
  .imageFluid {
    display: none;
  }
}