body {
  background-color: #fff;
  color: #000;
}
a {
  text-decoration: none;
}
input[type=checkbox] {
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.2);
  /* IE */
  -moz-transform: scale(1.2);
  /* FF */
  -webkit-transform: scale(1.2);
  /* Safari and Chrome */
  -o-transform: scale(1.2);
  /* Opera */
  transform: scale(1.2);
  cursor: pointer;
  top: 7px;
}
.errorText {
  color: red;
}
.description {
  font-family: "Calibri", sans-serif;
  font-size: 20px;
  font-weight: 300;
}
.wrapper {
  max-width: 1140px;
}
.underline50::after {
  content: "";
  display: block;
  width: 16%;
  margin: 0 auto;
  padding-bottom: 12px;
  border-bottom: 3px solid black;
}
.boxBorder {
  border-top: 1px solid #DEE2E6;
}
.btnLightblue,
.btnLightblue:hover {
  color: #fff;
  background-color: rgba(25, 41, 92, 0.68);
  border-color: rgba(25, 41, 92, 0.68);
  text-transform: uppercase;
}
.btnDarkblue,
.btnDarkblue:hover {
  color: #fff;
  background-color: #19295c;
  border-color: #19295c;
  text-transform: uppercase;
}
.btnLightblue:disabled {
  background-color: #9AA1B7;
}
.btnDarkblue:disabled {
  background-color: #19295c;
}
.navFUW {
  color: #fff;
  background-color: rgba(25, 41, 92, 0.5);
  text-transform: uppercase;
  padding: 15px 10px;
}
.navFUWActive {
  color: #fff;
  background-color: #19295c;
  text-transform: uppercase;
  padding: 15px 10px;
}
.inputUserInfo {
  border-left: 0;
  border-top: 0;
  border-right: 0;
  padding-bottom: 4px;
  border-radius: 0;
}
.checkboxAgreement {
  position: absolute;
}
.lblAgreement {
  cursor: pointer;
}
.fillUserData {
  cursor: pointer;
  color: #5563b5;
}
.textBlue {
  color: #5563b5;
}
.workshopButton {
  font-weight: bold;
}
.workshopButton:hover {
  font-weight: bold;
  background-color: #e2e6ea;
}