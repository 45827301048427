@font-face {
  font-family: "Roman";
  src: local("SiemensSans_Prof_Roman"), url("../fonts/SiemensSans_Prof_Roman.ttf") format("truetype");
}
@font-face {
  font-family: "Black";
  src: local("SiemensSans_Prof_Black"), url("../fonts/SiemensSans_Prof_Black.ttf") format("truetype");
}
.titleStyle {
  font-family: black;
}
body {
  margin: 0;
  background-color: #000028;
  color: white;
}
.fontFace {
  font-family: "Black";
}
.pageContainer {
  position: relative;
  min-height: 100vh;
  background-color: #000028;
  font-family: roman;
}
.content {
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.boxMain {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: white;
  height: 80%;
  padding: 80px 60px 0px 60px;
  width: 70%;
  margin: auto;
}
@media all and (min-width: 1700px) {
  .boxMain {
    width: 45%;
    padding: 80px 60px 0px 60px;
  }
}
@media all and (max-width: 1440px) {
  .boxMain {
    width: 100%;
    padding: 80px 60px 0px 60px;
  }
}
@media all and (max-width: 640px) {
  .boxMain {
    width: 100%;
    padding: 80px 40px 0px 40px;
  }
  .checkBox {
    margin: 0px;
  }
}
.firstHalf {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.secondHalf {
  width: 30%;
}
.section {
  height: auto;
  display: flex;
  justify-content: space-between;
  padding-bottom: 100px;
}
.sectionLeft {
  flex-direction: row-reverse;
}
.button {
  background-color: #ec6723;
  border: 1px solid #ec6723;
  color: white;
  padding: 8px 50px 8px 50px;
}
.button:hover {
  background-color: white;
  border: 1px solid white;
  color: #ec6723;
  padding: 8px 50px 8px 50px;
}
.logo {
  height: 23px;
  width: 130px;
  margin: 20px;
  position: absolute;
  top: 0;
  right: 0;
}