body {
  background-color: #fff;
  color: #000;
}
.wrapper {
  max-width: 1000px;
}
.fill {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.fill img {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
}
.flyer {
  width: 100px;
  display: block;
  margin: auto;
}
.description {
  color: #f06e9c;
  font-size: small;
  cursor: pointer;
}
.button {
  background-color: #890c58;
  color: white;
  padding: 5px 50px 5px 50px;
  border: none;
  text-transform: uppercase;
  width: fit-content;
  white-space: nowrap;
  text-align: center;
}
@media all and (max-width: 500px) {
  .button {
    padding: 5px 15px 5px 15px;
  }
}
.button:disabled {
  opacity: 0.5;
}
.customTextbox {
  border: 1px solid #333645;
  border-radius: 0;
}
.customBox {
  background-color: #fef1f6;
}
.promocodeBox {
  padding: 0px 10px 0px 10px;
  background-color: #ebebeb;
}
.confirmBox {
  border: 2px solid #d0d4d8;
  padding: 15px;
}
.confirmationButton {
  background-color: #ee7325;
  color: white;
  width: 100%;
  padding: 10px;
  border: none;
  display: block;
  text-align: center;
  text-decoration: none;
  font-size: large;
  font-weight: lighter;
}
.confirmationButton:hover {
  color: #dbdbdb;
}