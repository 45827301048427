.heading {
  font-family: "black";
  color: #0d0d0d;
  font-size: xx-large;
  font-weight: normal;
}
.pageContainer {
  position: relative;
  min-height: 100vh;
  background-color: white;
  padding-top: 40px;
}
.content {
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.boxMain {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: #0d0d0d;
  height: 80%;
  padding: 40px 0px 0px 0px;
  width: 80%;
  margin: auto;
}
.firstHalf {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.secondHalf {
  width: 30%;
}
.section {
  height: auto;
  display: flex;
  justify-content: space-between;
  padding-bottom: 100px;
}
.sectionLeft {
  flex-direction: row-reverse;
}
.button {
  background-color: #e77c3d;
  border: 1px solid #e77c3d;
  border-radius: 0px;
  color: white;
  padding: 8px 50px 8px 50px;
}
.button:hover {
  background-color: white;
  border: 1px solid #e77c3d;
  color: #e77c3d;
  padding: 8px 50px 8px 50px;
}
.button:disabled {
  background-color: #e77b3d6e;
  border: 1px solid #e77b3d6e;
  pointer-events: none;
}
.buttonLanguage {
  background-color: #0080ac;
  border: 1px solid #0080ac;
  border-radius: 0px;
  color: white;
  font-size: small;
}
.buttonLanguage:hover {
  background-color: #038dbb;
  border: 1px solid #0080ac;
  color: white;
}
.buttonGroup {
  padding-left: 0px;
}
.list {
  list-style: none;
  margin-bottom: 30px;
}
.listRight {
  color: #0080ac;
}
.hr {
  border: 1px solid black;
  opacity: 100;
  margin: 20px 0px 20px 0px;
}
.ticketGroup {
  margin: 30px 0px 90px 0px;
}
.ticketCheckbox {
  margin-right: 20px;
  width: 25px;
  height: 25px;
}
.ticketCheckbox:checked {
  background-color: #e77c3d;
  color: white;
  border-color: #e77c3d;
}
.ticketCheckbox:focus {
  border-color: #e77c3d;
}
.formCheck {
  margin: 10px 0px 0px 0px;
}
.formCheckLabel {
  padding: 3px;
}
.geology {
  background-color: white !important;
  color: #0d0d0d !important;
  border: 1px solid #cccccc !important;
}
.container {
  padding: 10px 30px 30px 30px;
}
.buttonGroup {
  position: absolute;
  top: 0;
  left: 0;
  translate: middle;
}
.textArea {
  width: 500px;
  height: 160px;
}
.ul {
  padding-left: 15px;
}
.languageSelection {
  color: white;
  padding: 5px 7px 5px 7px;
  border: 1px solid #0080ac;
  background-color: #0080ac;
  position: fixed;
  top: 0;
  left: 58%;
  margin: auto;
  font-size: smaller;
}
.ticketinoLogo {
  position: absolute;
  top: 0;
  left: 0;
  margin: 10px;
}
.textNotFound {
  color: #ee7325;
  font-weight: 900;
}
@media all and (min-width: 1700px) {
  .boxMain {
    width: 60%;
  }
}
@media all and (max-width: 1440px) {
  .boxMain {
    width: 80%;
    padding: 40px 10px 0px 10px;
  }
}
@media all and (max-width: 800px) {
  .boxMain {
    width: 100%;
    padding: 40px 10px 0px 10px;
    font-size: small;
  }
  .checkboxDiv {
    margin: 0px;
  }
  .ticketCheckbox {
    height: 15px;
    width: 15px;
  }
}
@media all and (max-width: 420px) {
  .boxMain {
    width: 100%;
    padding: 40px 10px 0px 10px;
    font-size: small;
  }
  .listRight {
    padding-left: 15px;
  }
  .checkboxDiv {
    margin: 0px;
  }
  .languageSelection {
    padding: 0;
    right: 0;
  }
  .textArea {
    width: 300px;
  }
}
@media all and (max-width: 400px) {
  .vr {
    display: none;
  }
}