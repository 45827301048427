body {
  background-color: #fff;
  color: #000;
}
.wrapper {
  max-width: 1000px;
}
a {
  text-decoration: none;
}
.hrEffect {
  border: 1px solid #000;
}
.customTextbox {
  border: 1px solid #333645;
  border-radius: 0;
}
.customButton {
  background-color: #cbeef5;
  border-radius: 0;
  color: #000;
  padding-left: 25px;
  padding-right: 25px;
}
.customButton2 {
  background-color: #929292;
  border-radius: 0;
  color: #000;
  padding-left: 25px;
  padding-right: 25px;
}
.customButton:disabled,
.customButton2:disabled {
  color: lightgray;
}
.footerBgColor {
  background-color: #cbeef5;
}
.headerDiv {
  overflow: hidden;
}
.confirmBox {
  background-color: #c8eef5;
  padding: 20px;
}
.confirmationButton {
  background-color: #c8eef5;
  width: 100%;
  padding: 15px;
  border: none;
  display: block;
  text-align: center;
  text-decoration: none;
  color: black;
  font-size: large;
  font-weight: lighter;
}
.confirmationButton:hover {
  color: #dbdbdb;
}
.formCheckInput {
  border: 1px solid black;
  height: 20px;
  width: 20px;
}
.spanMoreInfo {
  color: #ff8351;
}
.googleMap {
  top: 12.5%;
  left: 25%;
  translate: middle;
  width: 100%;
  height: 500px;
  z-index: 1;
  overflow: hidden;
}
.googleMap div:first-child {
  position: relative !important;
  height: 100% !important;
  width: 100% !important;
}
h1 {
  font-family: Futura, Trebuchet MS, Arial, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 36px;
}
h3 {
  font-family: Futura, Trebuchet MS, Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 15.4px;
}
.wrapper * {
  font-family: Futura, Trebuchet MS, Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 20px;
}